<template lang="pug">
  .booking-ticket-details.mt-3.mb-3
    .container
      .row(v-if="isDisabled")
        .offset-1
        .col-10
          booking-ticket-card(
            :booking-ticket="bookingTicket"
            :is-edit="true"
            :load-booking-ticket="loadBookingTicket"
          )
        .offset-1
      template(v-else)
        .row
          .offset-md-1.col-md-10.offset-md-1
            .row.text-center
              .col
                .background-circle.align-middle.mb-3(style="height: 85px; width: 85px;" :class="{ 'bg-td-grey-basic': !bookingTicket.Id, 'bg-tdays-basic': bookingStep >= 1 }")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'check']" size="3x" v-if="bookingStep > 1")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'user']" size="3x" v-else)
                span {{ $t("address") }}

              .col
                .background-circle.mb-3(style="height: 85px; width: 85px;" :class="{ 'bg-td-grey-basic': !isBookingStepValid(2), 'bg-tdays-basic': isBookingStepValid(2) }")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'check']" size="3x" v-if="bookingStep > 2")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'file-lines']" size="3x" v-else)
                span {{ $t("vouchers") }}

              .col
                .background-circle.align-middle.mb-3(style="height: 85px; width: 85px;" :class="{ 'bg-td-grey-basic': !isBookingStepValid(3), 'bg-tdays-basic': isBookingStepValid(3) }")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'check']" size="3x" v-if="bookingStep > 3")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'calendar-days']" size="3x" v-else)
                span {{ $t("dates") }}

              .col
                .background-circle.align-middle.mb-3(style="height: 85px; width: 85px;" :class="{ 'bg-td-grey-basic': !isBookingStepValid(4), 'bg-tdays-basic': isBookingStepValid(4) }")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'check']" size="3x" v-if="bookingStep > 4")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'user-plus']" size="3x" v-else)
                span {{ $t("fellowTravelers") }}

              .col
                a.background-circle.align-middle.mb-3(style="height: 85px; width: 85px;" :class="{ 'bg-td-grey-basic disabled': !bookingTicket.Id, 'cursor-pointer': bookingTicket.Id, 'bg-tbook-basic': !isSummaryVisible, 'bg-tdays-basic': isSummaryVisible }" @click="isSummaryVisible = !isSummaryVisible")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'table-list']" size="3x")
                span {{ $t("overview") }}

        .row
          .col-md-1.d-md-flex.align-items-center.d-sm-none
            .d-block.text-center
              a.background-circle.mb-2(style="height: 65px; width: 65px;" @click="previousPage" :class="{ 'cursor-pointer bg-tbook-basic': !isDisabled && bookingStep > 1, 'disabled bg-td-grey-basic': isDisabled || bookingStep == 1 }")
                font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'arrow-left']" size="3x")
              span {{ $t('back') }}

          .col-md-10.col-sm-12.mt-3
            // BOOKING TICKET OVERVIEW
            template(v-if="(bookingTicket.Id && isSummaryVisible) || (bookingTicket.Id && bookingStep == 5)")
              booking-ticket-overview(
                :booking-ticket="bookingTicket"
                :is-edit="true"
              )
              .row.mt-2(v-if="hosts.every((host) => host.ChannelPropertyTypeId && host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook)")
                .col-12
                  hr
                  b-form-checkbox(switch v-model="bookingTicket.IsBindingBooking") {{ $t("customer-data.checkToBook") }}


            // ADDRESS DETAILS
            address-booking-step(
              v-else-if="bookingStep == 1"
              :booking-ticket="bookingTicket"
              :address="address"
              :address-countries="addressCountries"
              :change-address="changeAddress"
            )

            vouchers-booking-step(
              v-else-if="bookingTicket.Id && bookingStep == 2"
              :booking-ticket="bookingTicket"
              :booking-ticket-vouchers="bookingTicketVouchers"
              :hosts="hosts"
              :products="products"
              :add-booking-ticket-voucher="addBookingTicketVoucher"
              :delete-booking-ticket-voucher="deleteBookingTicketVoucher"
            )

            // DATE SELECTION
            booking-dates-booking-step(
              v-else-if="bookingTicket.Id && bookingStep == 3"
              :booking-ticket="bookingTicket"
              :booking-ticket-vouchers="bookingTicketVouchers"
              :booking-ticket-voucher="bookingTicketVoucher"
              :booking-date-requests="bookingDateRequests"
              :booking-date-request="bookingDateRequest"
              :nights="nights"
              :hosts="hosts"
              :select-date="selectDate"
              :select-booking-date-request="selectBookingDateRequest"
              :add-booking-date-request="addBookingDateRequest"
              :delete-booking-date-request="deleteBookingDateRequest"
              :rate="rate"
            )

            // FELLOW TRAVELERS
            fellow-travelers-booking-step(
              v-else-if="bookingTicket.Id && bookingStep == 4"
              :fellow-travelers="fellowTravelers"
              :booking-ticket="bookingTicket"
              :booking-ticket-vouchers="bookingTicketVouchers"
              :address-countries="addressCountries"
            )

          .col-md-1.d-md-flex.align-items-center.d-sm-none
            .d-block.text-center
              template(v-if="bookingStep < 5")
                a.background-circle.mb-2(style="height: 65px; width: 65px;" @click="nextPage" :class="{ 'cursor-pointer bg-tbook-basic': !isSummaryVisible && bookingStep < 5, 'disabled bg-td-grey-basic': isSummaryVisible }")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'arrow-right']" size="3x")
                span {{ $t('next') }}

              template(v-else-if="bookingStep == 5")
                a.background-circle.mb-2(
                  @click="sendBookingToHost"
                  style="height: 65px; width: 65px;"
                  :class="{ 'cursor-pointer bg-tdays-basic': bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.Created, 'disabled bg-td-grey-basic': bookingTicket.BookingTicketStatusId != BookingTicketStatusEnum.Created }"
                )
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'envelope']" size="2x")
                span {{ $t('sendBooking') }}


        .row.mt-sm-4.mt-md-0
          .col-6
            .float-left.d-md-none.d-sm-block
              a.background-circle.mb-2(style="height: 65px; width: 65px;" @click="previousPage" :class="{ 'cursor-pointer bg-tbook-basic': !isDisabled && bookingStep > 1, 'disabled bg-td-grey-basic': isDisabled || bookingStep == 1 }")
                font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'arrow-left']" size="3x")
              .text-center
                span {{ $t('back') }}

          .col-6
            .float-right.d-md-none.d-sm-block
              template(v-if="bookingStep < 5")
                a.background-circle.mb-2(style="height: 65px; width: 65px;" @click="nextPage" :class="{ 'cursor-pointer bg-tbook-basic': !isSummaryVisible && bookingStep < 5, 'disabled bg-td-grey-basic': isSummaryVisible }")
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'arrow-right']" size="3x")
                .text-center
                  span {{ $t('next') }}

              template(v-else-if="bookingStep == 5")
                a.background-circle.mb-2(
                  @click="sendBookingToHost"
                  style="height: 65px; width: 65px;"
                  :class="{ 'cursor-pointer bg-tdays-basic': bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.Created, 'disabled bg-td-grey-basic': bookingTicket.BookingTicketStatusId != BookingTicketStatusEnum.Created }"
                )
                  font-awesome-icon.txt-white.ml-auto.mr-auto(:icon="['fas', 'envelope']" size="2x")
                .text-center
                  span {{ $t('sendBooking') }}

</template>

<script>
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { BookingTicketsComponent } from "@/components/bookingTickets/BookingTicketsComponent";
import EventBus from "@/event-bus";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import BookingTicketVouchersComponent from "@/components/bookingTicketVouchers/BookingTicketVouchersComponent";
import BookingDateRequestsComponent from "@/components/bookingDateRequests/BookingDateRequestsComponent";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import BookingTicketVoucher from "@/views/bookingTicketVouchers/BookingTicketVoucher.vue";
import { BookingTicketVoucherStatusEnum } from "@/utils/enums/bookingTicketVoucherStatus/BookingTicketVoucherStatusEnum.ts";
import BookingTicketVoucherEditing from "@/views/bookingTicketVouchers/BookingTicketVoucherEditing.vue";
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import BookingDatePicker from "@/views/components/datepicker/BookingDatePicker.vue";
import BookingDateRequestsEditing from "@/views/bookingDateRequests/BookingDateRequestsEditing.vue";
import AddressDetails from "@/views/addresses/AddressDetails.vue";
import AddressesComponent from "@/components/addresses/AddressesComponents";
import HostsComponent from "@/components/hosts/HostsComponent";
import Voucher from "@/views/vouchers/Voucher.vue";
import ProductsComponent from "@/components/products/ProductsComponent";
import AddressCountriesComponent from "@/components/addressCountries/AddressCountriesComponent";
import FellowTravelersEditing from "@/views/fellowTravelers/FellowTravelersEditing.vue";
import BookingTicketOverview from "@/views/bookingTickets/BookingTicketOverview.vue";
import FellowTravelersComponent from "@/components/fellowTravelers/FellowTravelersComponent";
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";
import HyperGuestComponent from "@/components/hyperGuest/HyperGuestComponent";
import HyperGuestRate from "@/views/hyperGuest/HyperGuestRate.vue";
import moment from "moment";
import AddressBookingStep from "@/views/bookingSteps/AddressBookingStep.vue";
import VouchersBookingStep from "@/views/bookingSteps/VouchersBookingStep.vue";
import BookingDatesBookingStep from "@/views/bookingSteps/BookingDatesBookingStep.vue";
import FellowTravelersBookingStep from "@/views/bookingSteps/FellowTravelersBookingStep.vue";
import { UtilsComponent } from "@/components/utils/UtilsComponent";
import { OriginPlatformsEnum } from "@/utils/enums/originPlatforms/OriginPlatformsEnum.ts";
import BookingTicketCard from "@/views/bookingTickets/BookingTicketCard.vue";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";

export default {
  name: "BookingTicketDetails",
  components: {
    BookingTicketCard,
    FellowTravelersBookingStep,
    BookingDatesBookingStep,
    VouchersBookingStep,
    AddressBookingStep,
    HyperGuestRate,
    BookingDateRequests,
    BookingTicketOverview,
    FellowTravelersEditing,
    Voucher,
    AddressDetails,
    BookingDateRequestsEditing,
    BookingDatePicker,
    BookingTicketVoucherEditing,
    BookingTicketVoucher,
  },
  props: {
    bookingTicketGuid: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      bookingTicket: {
        IsBindingBooking: false,
        BookingTicketStatusId: BookingTicketStatusEnum.Created,
        BookingTicketTypeId: BookingTicketTypesEnum.SameArrivalDay,
        OriginPlatformId: OriginPlatformsEnum.TouriBook,
      },
      bookingTicketVouchers: [],
      bookingTicketVoucher: undefined,
      bookingDateRequests: [],
      bookingDateRequest: undefined,
      hosts: [],
      products: [],
      nights: [],
      rate: undefined,

      addressCountries: [],

      BookingTicketStatusEnum: BookingTicketStatusEnum,
      BookingTicketTypesEnum: BookingTicketTypesEnum,
      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,

      address: {
        FormOfAddress: "Herr",
        Email: this.$session.get("email"),
        Country: "DE",
      },
      fellowTravelers: [],
      maxproductPersons: 0,

      bookingStep: 1,
      isSummaryVisible: false,
    };
  },
  async mounted() {
    try {
      this.loadBookingTicket();
      if (!this.bookingTicketGuid || this.bookingTicketGuid == "new") {
        this.loadAddressCountries();
      }
      if (this.$router.currentRoute.query.bookingStep) {
        this.bookingStep = parseInt(
          this.$router?.currentRoute?.query?.bookingStep,
        );
      }
      this.isSummaryVisible =
        this.$router?.currentRoute?.query?.isSummaryVisible;
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadBookingTicket() {
      try {
        if (!this.bookingTicketGuid || this.bookingTicketGuid == "new") {
          return;
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const bookingTicket = await bookingTicketsComponent.getBookingTicket(
          this.$apollo,
          undefined,
          this.bookingTicketGuid,
        );
        EventBus.$emit("changeLoadingState", false);
        if (!bookingTicket) {
          return this.$alert("Es konnte keine Buchung geladen werden.");
        }
        this.bookingTicket = bookingTicket;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async addLoggedInVoucher() {
      try {
        const voucherId = this.$session.get("voucherId");
        if (!voucherId) {
          return;
        }
        if (
          this.bookingTicketVouchers.some(
            (bookingTicketVoucher) =>
              bookingTicketVoucher.VoucherId == voucherId,
          )
        ) {
          return;
        }
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          voucherId,
        );
        this.bookingTicketVouchers.push({
          VoucherId: voucher.Id,
          BookingTicketVoucherStatusId: BookingTicketVoucherStatusEnum.Created,
        });
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddressCountries() {
      try {
        const addressCountriesComponent = new AddressCountriesComponent();
        const addressCountries =
          await addressCountriesComponent.getAddressCountries(this.$apollo);
        if (!addressCountries) {
          return;
        }
        this.addressCountries = addressCountries;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingTicketVouchers() {
      try {
        if (!this.bookingTicket?.Id) {
          return;
        }
        const bookingTicketVouchersComponent =
          new BookingTicketVouchersComponent();
        const bookingTicketVouchers =
          await bookingTicketVouchersComponent.getBookingTicketVouchers(
            this.$apollo,
            this.bookingTicket?.Id,
          );
        if (!bookingTicketVouchers) {
          return;
        }
        this.bookingTicketVouchers = bookingTicketVouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingDateRequests() {
      try {
        const bookingDateRequestsComponent = new BookingDateRequestsComponent();
        const bookingDateRequests =
          await bookingDateRequestsComponent.getBookingDateRequests(
            this.$apollo,
            this.bookingTicket?.Id,
          );
        this.bookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddress() {
      try {
        if (!this.bookingTicket?.AddressId) {
          return;
        }
        const addressesComponent = new AddressesComponent();
        const address = await addressesComponent.getAddress(
          this.$apollo,
          this.bookingTicket.AddressId,
        );
        if (!address) {
          return;
        }
        this.address = address;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadFellowTravelers() {
      try {
        const fellowTravelersComponent = new FellowTravelersComponent();
        const fellowTravelers =
          await fellowTravelersComponent.getFellowTravelers(
            this.$apollo,
            this.bookingTicket?.Id,
          );
        if (
          !fellowTravelers ||
          !Array.isArray(fellowTravelers) ||
          !fellowTravelers.length
        ) {
          return;
        }
        this.fellowTravelers = fellowTravelers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async addBookingTicketVoucher(voucherId, code) {
      try {
        if (!voucherId) {
          return {
            IsSuccess: false,
            Message: "Bitte geben Sie eine Gutscheinnummer ein.",
          };
        }
        if (!code) {
          return {
            IsSuccess: false,
            Message: "Bitte geben Sie einen Code ein.",
          };
        }
        if (
          this.bookingTicketVouchers.some(
            (bookingTicketVoucher) =>
              bookingTicketVoucher.VoucherId == voucherId,
          )
        ) {
          return {
            IsSuccess: false,
            Message: "Der Gutschein ist bereits Teil der Buchung.",
          };
        }
        const vouchersComponent = new VouchersComponent();
        EventBus.$emit("changeLoadingState", true);
        const checkedVoucherBookingAvailability =
          await vouchersComponent.checkVoucherBookingAvailability(
            this.$apollo,
            voucherId,
            code,
          );
        EventBus.$emit("changeLoadingState", false);
        if (!checkedVoucherBookingAvailability) {
          return {
            IsSuccess: false,
            Message:
              "Der Gutschein konnte nicht hinzugefügt werden. Bitte versuchen Sie es später erneut oder kontaktieren Sie den touriDat-Support.",
          };
        }
        if (checkedVoucherBookingAvailability.IsSuccess) {
          this.bookingTicketVouchers.push({
            VoucherId: voucherId,
            BookingTicketVoucherStatusId:
              BookingTicketVoucherStatusEnum.Created,
          });
        }
        return checkedVoucherBookingAvailability;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async deleteBookingTicketVoucher(bookingTicketVoucher) {
      try {
        if (bookingTicketVoucher.Id) {
          const confirmed = await this.$confirm(
            "Sind Sie sicher, dass Sie den Gutschein inklusive aller Daten aus der Buchung entfernen möchten?",
          )
            .then()
            .catch((e) => e);
          if (!confirmed) {
            return;
          }
          const bookingTicketVouchersComponent =
            new BookingTicketVouchersComponent();
          EventBus.$emit("changeLoadingState", true);
          const deletedBookingTicketVoucher =
            await bookingTicketVouchersComponent.deleteBookingTicketVouchers(
              this.$apollo,
              bookingTicketVoucher.Id,
            );
          EventBus.$emit("changeLoadingState", false);
          if (!deletedBookingTicketVoucher) {
            return this.$alert("Der Gutschein konnte nicht gelöscht werden.");
          }
          if (deletedBookingTicketVoucher.IsSuccess) {
            if (!deletedBookingTicketVoucher.Message) {
              return;
            }
            return this.$alert(deletedBookingTicketVoucher.Message);
          }
        }
        this.bookingTicketVouchers.splice(
          this.bookingTicketVouchers.indexOf(bookingTicketVoucher),
          1,
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    selectBookingDateRequest(bookingDateRequest) {
      try {
        this.bookingDateRequest = bookingDateRequest;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async selectDate(date, bookingTicketVoucher = undefined) {
      try {
        const productsComponent = new ProductsComponent();
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.SameArrivalDay
        ) {
          for (const bookingTicketVoucher of this.bookingTicketVouchers) {
            const product = await productsComponent.getProduct(
              this.$apollo,
              undefined,
              bookingTicketVoucher.VoucherId,
            );
            if (!product) {
              continue;
            }
            bookingTicketVoucher.FromDate = date;
            bookingTicketVoucher.ToDate = moment(date)
              .add(product.Nights, "days")
              .toDate();
          }
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.CombineTravelPeriod
        ) {
          let nights = 0;
          for (const bookingTicketVoucher of this.bookingTicketVouchers) {
            const product = await productsComponent.getProduct(
              this.$apollo,
              undefined,
              bookingTicketVoucher.VoucherId,
            );
            if (!product) {
              continue;
            }
            nights += product.Nights;
          }
          this.bookingDateRequest.FromDate = date;
          this.bookingDateRequest.ToDate = moment(date)
            .add(nights, "days")
            .toDate();
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          const product = await productsComponent.getProduct(
            this.$apollo,
            undefined,
            bookingTicketVoucher.VoucherId,
          );
          if (!product) {
            return;
          }
          this.bookingDateRequest.FromDate = date;
          this.bookingDateRequest.ToDate = moment(date)
            .add(product.Nights, "days")
            .toDate();
        }
        this.bookingDateRequest.Date = date;
        EventBus.$emit("updateCalendarHighlights", bookingTicketVoucher);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    addBookingDateRequest(bookingTicketVoucherId) {
      try {
        const bookingDateRequest = {
          BookingDateRequestStatusId: BookingDateRequestStatusEnum.DateCreated,
          Date: undefined,
        };
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          bookingDateRequest.BookingTicketVoucherId = bookingTicketVoucherId;
        } else {
          bookingDateRequest.BookingTicketId = this.bookingTicket.Id;
        }
        if (
          !this.bookingDateRequests ||
          !Array.isArray(this.bookingDateRequests)
        ) {
          this.bookingDateRequests = [];
        }
        this.bookingDateRequests.push(bookingDateRequest);
        this.selectBookingDateRequest(bookingDateRequest);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async deleteBookingDateRequest(bookingDateRequest) {
      try {
        if (bookingDateRequest.Id) {
          const confirmed = await this.$confirm(
            "Sind Sie sicher, dass Sie den Anreisetag löschen möchten?",
          )
            .then()
            .catch((e) => e);
          if (!confirmed) {
            return;
          }
          const bookingDateRequestsComponent =
            new BookingDateRequestsComponent();
          EventBus.$emit("changeLoadingState", true);
          const deletedBookingDateRequest =
            await bookingDateRequestsComponent.deleteBookingDateRequest(
              this.$apollo,
              bookingDateRequest.Id,
            );
          EventBus.$emit("changeLoadingState", false);
          if (!deletedBookingDateRequest) {
            return this.$alert(
              "Der Anreisetag konnte nicht gelöscht werden. Bitte versuchen Sie es später nochmal oder wenden Sie sich an das touriDat-Service Team.",
            );
          }
          if (!deletedBookingDateRequest.IsSuccess) {
            return this.$alert(deletedBookingDateRequest.Message);
          }
        }
        this.bookingDateRequest = undefined;
        this.bookingDateRequests.splice(
          this.bookingDateRequests.indexOf(bookingDateRequest),
          1,
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    changeAddress(address) {
      try {
        if (!this.address) {
          return (this.address = {
            FormOfAddress: "Herr",
            Email: this.$session.get("email"),
          });
        }
        this.address = address;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveBookingTicket(isThirdPartyBooking = false) {
      try {
        let bookingTicketVouchers = undefined;
        let bookingDateRequests = undefined;
        let address = undefined;
        let fellowTravelers = undefined;
        if (this.bookingStep == 1) {
          address = this.address;
        }
        if (this.bookingStep == 2) {
          bookingTicketVouchers = this.bookingTicketVouchers;
        }
        if (this.bookingStep == 3) {
          bookingDateRequests = this.bookingDateRequests.filter((b) => b.Date);
          if (
            this.bookingTicket.BookingTicketTypeId ==
            BookingTicketTypesEnum.DifferentArrivalDays
          ) {
            bookingDateRequests = bookingDateRequests.filter(
              (b) => b.BookingTicketVoucherId,
            );
          } else {
            bookingDateRequests = bookingDateRequests.filter(
              (b) => b.BookingTicketId,
            );
          }
        }
        if (this.bookingStep == 4) {
          fellowTravelers = this.fellowTravelers;
          if (
            this.bookingTicket.BookingTicketTypeId ==
            BookingTicketTypesEnum.DifferentArrivalDays
          ) {
            fellowTravelers = fellowTravelers.filter(
              (b) => b.BookingTicketVoucherId,
            );
          } else {
            fellowTravelers = fellowTravelers.filter((b) => b.BookingTicketId);
          }
        }
        if (this.bookingStep == 5) {
          await this.loadBookingDateRequests();
          bookingDateRequests = this.bookingDateRequests.filter(
            (b) =>
              b.BookingDateRequestStatusId ==
              BookingDateRequestStatusEnum.DateCreated,
          );
          let bookingDateRequestStatus =
            BookingDateRequestStatusEnum.DateRequested;
          if (isThirdPartyBooking) {
            bookingDateRequestStatus = BookingDateRequestStatusEnum.DateBooked;
          }
          bookingDateRequests.forEach(
            (bookingDateRequest) =>
              (bookingDateRequest.BookingDateRequestStatusId =
                bookingDateRequestStatus),
          );
        }
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          this.bookingTicket.IsBindingBooking = false;
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedBookingTicket =
          await bookingTicketsComponent.saveBookingTicket(
            this.$apollo,
            this.bookingTicket,
            bookingTicketVouchers,
            bookingDateRequests,
            address,
            fellowTravelers,
          );
        EventBus.$emit("changeLoadingState", false);
        return savedBookingTicket;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async nextPage() {
      try {
        if (this.bookingStep >= 5) {
          return;
        }
        if (this.isSummaryVisible) {
          return;
        }
        const savedBookingTicket = await this.saveBookingTicket();
        if (!savedBookingTicket?.IsSuccess) {
          return this.$alert(savedBookingTicket.Message);
        }
        const route = {
          name: "BookingTicketDetails",
          query: {
            bookingStep: this.bookingStep + 1,
          },
        };
        if (this.bookingTicketGuid == "new" || !this.bookingTicketGuid) {
          route.params = { bookingTicketGuid: savedBookingTicket.Guid };
        } else {
          route.params = { bookingTicketGuid: this.bookingTicketGuid };
        }
        if (this.bookingStep + 1 == 5) {
          route.query.isSummaryVisible = true;
        }
        this.$router.push(route).catch(() => {});
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async previousPage() {
      try {
        if (this.isDisabled) {
          return;
        }
        if (this.bookingStep == 1) {
          return;
        }
        const confirmed = await this.$confirm(
          this.$t("previousStepConfirmation"),
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        this.$router
          .push({
            name: "BookingTicketDetails",
            params: {
              bookingTicketGuid: this.bookingTicketGuid,
            },
            query: {
              bookingStep: this.bookingStep - 1,
            },
          })
          .catch(() => {});
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    isBookingStepValid(bookingStep) {
      try {
        if (!bookingStep) {
          return false;
        }
        return this.bookingStep >= bookingStep;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async handleFellowTravelers() {
      try {
        const productsComponent = new ProductsComponent();
        if (
          this.bookingTicket?.BookingTicketTypeId ==
            BookingTicketTypesEnum.SameArrivalDay ||
          this.bookingTicket?.BookingTicketTypeId ==
            BookingTicketTypesEnum.CombineTravelPeriod
        ) {
          let fellowTravelersCount = 0;
          if (
            this.bookingTicket?.BookingTicketTypeId ==
            BookingTicketTypesEnum.SameArrivalDay
          ) {
            for (const bookingTicketVoucher of this.bookingTicketVouchers) {
              const product = await productsComponent.getProduct(
                this.$apollo,
                undefined,
                bookingTicketVoucher.VoucherId,
              );
              if (!product) {
                return this.$alert(
                  `Es konnte kein Produkt für den Gutschein ${bookingTicketVoucher.VoucherId} geladen werden.`,
                );
              }
              fellowTravelersCount += product.Personen;
            }
          } else if (
            this.bookingTicket?.BookingTicketTypeId ==
            BookingTicketTypesEnum.CombineTravelPeriod
          ) {
            let products = this.products;
            products = products.sort((a, b) => {
              return b.Personen - a.Personen;
            });
            const maxProductPersons = products[0]?.Personen;
            if (!maxProductPersons) {
              return;
            }
            fellowTravelersCount = maxProductPersons;
          }
          while (
            this.fellowTravelers.filter(
              (ft) => ft.BookingTicketId == this.bookingTicket?.Id,
            ).length <
            fellowTravelersCount - 1
          ) {
            const fellowTraveler = {
              BookingTicketId: this.bookingTicket?.Id,
              Country: "DE",
            };
            this.fellowTravelers.push(fellowTraveler);
          }
        } else if (
          this.bookingTicket?.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          for (const bookingTicketVoucher of this.bookingTicketVouchers) {
            const product = await productsComponent.getProduct(
              this.$apollo,
              undefined,
              bookingTicketVoucher.VoucherId,
            );
            if (!product) {
              return this.$alert(
                `Es konnte kein Produkt für den Gutschein ${bookingTicketVoucher.VoucherId} geladen werden.`,
              );
            }
            while (
              this.fellowTravelers.filter(
                (ft) => ft.BookingTicketVoucherId == bookingTicketVoucher.Id,
              ).length <
              product.Personen - 1
            ) {
              const fellowTraveler = {
                BookingTicketVoucherId: bookingTicketVoucher.Id,
                Country: "DE",
              };
              this.fellowTravelers.push(fellowTraveler);
            }
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHosts() {
      try {
        const hosts = [];
        const hostsComponent = new HostsComponent();
        for (const bookingTicketVoucher of this.bookingTicketVouchers) {
          const host = await hostsComponent.getHost(
            this.$apollo,
            undefined,
            bookingTicketVoucher.VoucherId,
          );
          if (!host || hosts.some((h) => h.Id == host?.Id)) {
            continue;
          }
          hosts.push(host);
        }
        this.hosts = hosts;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async sendBookingToHost() {
      try {
        if (
          this.bookingTicket.BookingTicketStatusId !=
          BookingTicketStatusEnum.Created
        ) {
          return;
        }
        let confirmationString = "";
        const utilsComponent = new UtilsComponent();
        const isThirdPartyBooking = utilsComponent.getIsThirdPartyBooking(
          this.hosts,
        );
        if (isThirdPartyBooking) {
          confirmationString = `<b>${this.$t("thirdPartyBookingConfirmation")}</b>`;
        } else {
          confirmationString = this.$t("bookingConfirmation");
        }
        const confirmed = await this.$confirm(undefined, undefined, undefined, {
          html: `<span>${confirmationString}</span>`,
        })
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const savedBookingTicket =
          await this.saveBookingTicket(isThirdPartyBooking);
        if (!savedBookingTicket) {
          return this.$alert("Die Buchung konnte nicht gespeichert werden.");
        }
        if (savedBookingTicket.Message) {
          this.$alert(savedBookingTicket.Message);
        }
        if (savedBookingTicket.IsSuccess) {
          this.$router
            .push({
              name: "BookingTicketDetails",
              params: { bookingTicketGuid: savedBookingTicket.Guid },
            })
            .catch(() => {});
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadRate() {
      try {
        const hyperGuestComponent = new HyperGuestComponent();
        const rate = await hyperGuestComponent.getRate(
          this.$apollo,
          this.bookingDateRequest?.Date,
          this.bookingTicket?.Id,
          this.bookingTicketVoucher?.Id,
        );
        if (!rate) {
          return undefined;
        }
        this.rate = rate;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async updateNights() {
      try {
        if (!this.bookingTicketVouchers) {
          return;
        }
        const nights = [];
        const productsComponent = new ProductsComponent();
        for (const bookingTicketVoucher of this.bookingTicketVouchers) {
          const product = await productsComponent.getProduct(
            this.$apollo,
            undefined,
            bookingTicketVoucher.VoucherId,
          );
          if (!product) {
            continue;
          }
          nights.push(product.Nights);
        }
        this.nights = nights;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProducts() {
      try {
        const products = [];
        const productsComponent = new ProductsComponent();
        for (const bookingTicketVoucher of this.bookingTicketVouchers) {
          const product = await productsComponent.getProduct(
            this.$apollo,
            undefined,
            bookingTicketVoucher.VoucherId,
          );
          if (!product || products.some((p) => p.Id == product?.Id)) {
            continue;
          }
          products.push(product);
        }
        this.products = products;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async handleLoading() {
      try {
        if (!this.bookingTicket.Id) {
          return;
        } else if (this.bookingStep == 1) {
          this.loadAddressCountries();
          this.loadAddress();
        } else if (this.bookingStep == 2) {
          this.loadBookingTicketVouchers();
        } else if (this.bookingStep == 3) {
          this.loadBookingTicketVouchers();
          this.loadBookingDateRequests();
        } else if (this.bookingStep == 4) {
          this.loadAddressCountries();
          await this.loadBookingTicketVouchers();
          await this.loadFellowTravelers();
          this.handleFellowTravelers();
        } else if (this.bookingStep == 5) {
          this.loadBookingTicketVouchers();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    handleBookingDateRequests() {
      try {
        if (
          this.bookingDateRequests &&
          Array.isArray(this.bookingDateRequests) &&
          this.bookingDateRequests.length
        ) {
          return;
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          for (const bookingTicketVoucher of this.bookingTicketVouchers) {
            this.bookingDateRequests.push({
              BookingTicketVoucherId: bookingTicketVoucher.Id,
              BookingDateRequestStatusId:
                BookingDateRequestStatusEnum.DateCreated,
              Date: undefined,
            });
          }
        } else {
          this.bookingDateRequests.push({
            BookingTicketId: this.bookingTicket.Id,
            BookingDateRequestStatusId:
              BookingDateRequestStatusEnum.DateCreated,
            Date: undefined,
          });
        }
        return this.bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    isSummaryVisible() {
      try {
        if (typeof this.isSummaryVisible !== "boolean") {
          return;
        }
        this.$router
          .push({
            name: "BookingTicketDetails",
            params: { bookingTicketGuid: this.bookingTicketGuid },
            query: {
              bookingStep: this.bookingStep,
              isSummaryVisible: this.isSummaryVisible,
            },
          })
          .catch(() => {});
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async bookingStep() {
      try {
        this.handleLoading();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    "bookingTicket.Id"() {
      try {
        this.handleLoading();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async bookingTicketVouchers() {
      try {
        this.addLoggedInVoucher();
        if (
          !this.bookingTicketVouchers ||
          !Array.isArray(this.bookingTicketVouchers) ||
          !this.bookingTicketVouchers.length
        ) {
          return;
        }
        this.loadHosts();
        this.loadProducts();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    hosts() {
      try {
        if (!this.hosts || !Array.isArray(this.hosts) || !this.hosts.length) {
          return;
        }
        if (
          this.bookingTicket.BookingTicketTypeId ==
            BookingTicketTypesEnum.SameArrivalDay &&
          !this.isSameArrivalDayAvailable?.IsSuccess
        ) {
          this.bookingTicket.BookingTicketTypeId = null;
          return this.$alert(
            `${this.isSameArrivalDayAvailable.Message} Bitte wählen Sie einen anderen Buchungstypen aus.`,
          );
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
            BookingTicketTypesEnum.CombineTravelPeriod &&
          !this.isCombineTravelPeriodAvailable?.IsSuccess
        ) {
          this.bookingTicket.BookingTicketTypeId = null;
          return this.$alert(
            `${this.isCombineTravelPeriodAvailable.Message} Bitte wählen Sie einen anderen Buchungstypen aus.`,
          );
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
            BookingTicketTypesEnum.DifferentArrivalDays &&
          !this.areDifferentArrivalDaysAvailable?.IsSuccess
        ) {
          this.bookingTicket.BookingTicketTypeId = null;
          return this.$alert(
            `${this.areDifferentArrivalDaysAvailable.Message} Bitte wählen Sie einen anderen Buchungstypen aus.`,
          );
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    products() {
      try {
        this.updateNights();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    maxProductPersons() {
      try {
        if (!this.maxProductPersons) {
          return;
        }
        if (
          !this.fellowTravelers ||
          !Array.isArray(this.fellowTravelers) ||
          !this.fellowTravelers.length
        ) {
          return;
        }
        while (this.fellowTravelers.length < this.maxProductPersons) {
          const fellowTraveler = {
            BookingTicketId: this.bookingTicket?.Id,
          };
          this.fellowTravelers.push(fellowTraveler);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    "bookingTicket.BookingTicketStatusId"() {
      try {
        if (
          this.bookingTicket?.BookingTicketStatusId !=
          BookingTicketStatusEnum.Created
        ) {
          return (this.bookingStep = 5);
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async "bookingDateRequest.Date"() {
      try {
        if (!this.bookingDateRequest?.Date) {
          return;
        }
        this.loadRate();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingDateRequests() {
      try {
        this.handleBookingDateRequests();
        EventBus.$emit("updateCalendarHighlights");
        if (!this.bookingDateRequests.length) {
          return;
        }
        if (this.bookingDateRequest) {
          return;
        }
        const bookingDateRequest = this.bookingDateRequests.find(
          (b) => !b.Date,
        );
        if (bookingDateRequest) {
          return this.selectBookingDateRequest(bookingDateRequest);
        }
        this.selectBookingDateRequest(
          this.bookingDateRequests[this.bookingDateRequests.length - 1],
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    isDisabled() {
      try {
        if (
          this.bookingTicket?.BookingTicketStatusId ==
          BookingTicketStatusEnum.Created
        ) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return true;
      }
    },
    isSameArrivalDayAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getIsSameArrivalDayAvailable(
          this.hosts,
          this.products,
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    isCombineTravelPeriodAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getIsCombineTravelPeriodAvailable(
          this.bookingTicketVouchers,
          this.hosts,
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    areDifferentArrivalDaysAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getAreDifferentArrivalDaysAvailable(
          this.bookingTicketVouchers,
          this.hosts,
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
